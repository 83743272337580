import React from 'react';
import styled from 'styled-components';

const MemoriasContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadius.large};
  box-shadow: ${props => props.theme.shadows.large};

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 2rem;
  font-size: clamp(1.5rem, 4vw, 2.5rem);

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

const MemoriasGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 0.5rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    padding: 0.25rem;
  }
`;

const MemoriaCard = styled.div`
  background: ${props => props.theme.colors.background.primary};
  border-radius: ${props => props.theme.borderRadius.medium};
  padding: 1.5rem;
  box-shadow: ${props => props.theme.shadows.small};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.shadows.medium};
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem;
    
    &:hover {
      transform: none;
    }
  }
`;

const MemoriaTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  font-size: clamp(1.1rem, 2vw, 1.3rem);
`;

const MemoriaDescription = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: 1rem;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
`;

const DownloadButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: ${props => props.theme.borderRadius.small};
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: clamp(0.9rem, 1.5vw, 1rem);

  &:hover {
    background-color: ${props => props.theme.colors.hover.primary};
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
    padding: 0.5rem 1rem;
  }
`;

const Memorias = () => {
  const memorias = [
    {
        id: 1,
        title: "Memoria 2023",
        description: "Resumen de actividades y logros del año 2022",
        url: "/memorias/Memoria actividades 2023.pdf"
      },
    
    {
      id: 2,
      title: "Memoria 2022",
      description: "Resumen de actividades y logros del año 2022",
      url: "/memorias/Memoria actividades 2022.pdf"
    },
    {
      id: 3,
      title: "Memoria 2021",
      description: "Resumen de actividades y logros del año 2021",
      url: "/memorias/Memoria actividades 2021.pdf"
    },
    {
      id: 4,
      title: "Memoria 2020",
      description: "Resumen de actividades y logros del año 2020",
      url: "/memorias/Memoria actividades 2020.pdf"
    },
    {
      id: 5,
      title: "Memoria 2019",
      description: "Resumen de actividades y logros del año 2019",
      url: "/memorias/Memoria actividades 2019.pdf"
    },
    {
      id: 6,
      title: "Memoria 2018",
      description: "Resumen de actividades y logros del año 2018",
      url: "/memorias/Memoria actividades 2018.pdf"
    },
    {
        id: 7,
        title: "Memoria 2017",
        description: "Resumen de actividades y logros del año 2021",
        url: "/memorias/Memoria actividades 2017.pdf"
      },
      {
        id: 8,
        title: "Memoria 2016",
        description: "Resumen de actividades y logros del año 2020",
        url: "/memorias/Memoria actividades 2016.pdf"
      },
      {
        id: 9,
        title: "Memoria 2015",
        description: "Resumen de actividades y logros del año 2019",
        url: "/memorias/Memoria actividades 2015.pdf"
      },
      {
        id: 10,
        title: "Memoria 2014",
        description: "Resumen de actividades y logros del año 2018",
        url: "/memorias/Memoria actividades 2014.pdf"
      }
  ];

  return (
    <MemoriasContainer>
      <Title>Memorias Anuales</Title>
      <MemoriasGrid>
        {memorias.map((memoria) => (
          <MemoriaCard key={memoria.id}>
            <MemoriaTitle>{memoria.title}</MemoriaTitle>
            <MemoriaDescription>{memoria.description}</MemoriaDescription>
            <DownloadButton 
              href={memoria.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver Memoria
            </DownloadButton>
          </MemoriaCard>
        ))}
      </MemoriasGrid>
    </MemoriasContainer>
  );
};

export default Memorias; 