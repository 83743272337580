import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube, FaWikipediaW } from 'react-icons/fa6';

const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #9932CC, #8A2BE2);
  color: white;
  padding: 1.5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
`;

const SocialSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const SocialLink = styled.a`
  color: white;
  font-size: 1.4rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    color: #FFD700;
    transform: translateY(-2px);
  }
`;

const ContactSection = styled.div`
  text-align: right;
  font-size: 0.9rem;
`;

const DirectionTitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.3rem 0;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ContactInfo = styled.p`
  margin: 0.2rem 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;
  
  &:last-child {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    opacity: 0.7;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;
`;

const LogoLink = styled.a`
  display: inline-block !important;
  position: static !important;
  width: auto !important;
  height: auto !important;
  pointer-events: auto !important;
  
  /* Resetear cualquier atributo de posicionamiento */
  transform: none !important;
  left: auto !important;
  top: auto !important;
`;

const LogoImage = styled.img.attrs(() => ({
  onLoad: (e) => {
    e.target.removeAttribute('bis_size');
    e.target.removeAttribute('bis_id');
  }
}))`
  height: 60px !important;
  width: auto !important;
  position: static !important;
  left: unset !important;
  transform: none !important;
  opacity: 1 !important;
  visibility: visible !important;
  display: block !important;
`;

const PertenecemosText = styled.p`
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: white;
`;

const CenterSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;

  @media (max-width: 768px) {
    order: 3;
    width: 100%;
    margin: 1rem 0;
  }
`;

const Footer = () => {
  useEffect(() => {
    const cleanAttributes = () => {
      const images = document.querySelectorAll('img[src*="logo-pap"]');
      images.forEach(img => {
        // Limpiar atributos inmediatamente
        img.removeAttribute('bis_size');
        img.removeAttribute('bis_id');
        img.style.position = 'static';
        img.style.left = 'auto';
        
        // Establecer un observador para mantenerlo limpio
        const observer = new MutationObserver((mutations) => {
          mutations.forEach(() => {
            img.removeAttribute('bis_size');
            img.removeAttribute('bis_id');
            img.style.position = 'static';
            img.style.left = 'auto';
          });
        });

        observer.observe(img, {
          attributes: true,
          attributeFilter: ['bis_size', 'bis_id', 'style']
        });
      });
    };

    // Ejecutar inmediatamente
    cleanAttributes();
    
    // Ejecutar después de un breve retraso para asegurar que se aplique
    const timeoutId = setTimeout(cleanAttributes, 100);
    
    // Configurar un intervalo para seguir limpiando
    const intervalId = setInterval(cleanAttributes, 1000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <FooterContainer>  
      <SocialSection>
        <h3>Encuéntranos en</h3>
        <SocialLinks>
          <SocialLink href="https://www.facebook.com/share/14gU5TvyG3g/" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/tf_comadres/profilecard/?igsh=MTl" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://x.com/TComadres71886?t=iL3T4F76S-h12sOKuCYkDg&s=08" target="_blank" rel="noopener noreferrer">
            <FaXTwitter />
          </SocialLink>          
          <SocialLink href="https://www.youtube.com/@comadresfeministas" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/@comadresfeministas" target="_blank" rel="noopener noreferrer">
            <FaWikipediaW />
          </SocialLink>
        </SocialLinks>
      </SocialSection>

      <CenterSection>
        <PertenecemosText>Pertenecemos a</PertenecemosText>
        <LogosContainer>
          <LogoLink href="https://contraelborradodelasmujeres.org/" target="_blank" rel="noopener noreferrer">
            <LogoImage 
              src="/cbm-logo_logo_color.png" 
              alt="CBM Logo" 
            />
          </LogoLink>
          <LogoLink href="https://aboliciondelaprostitucion.wordpress.com/" target="_blank" rel="noopener noreferrer">
            <LogoImage 
              src="/logo-pap.webp" 
              alt="PAP Logo" 
            />
          </LogoLink>
        </LogosContainer>
      </CenterSection>

      <ContactSection>
        <DirectionTitle>DIRECCIÓN</DirectionTitle>
        <ContactInfo>C/ Canga Argüelles, 16-18 - C.P. 33202 - Gijón (Asturias)</ContactInfo>
        <ContactInfo>Casa de Encuentro de las Mujeres, Oficina nº 8</ContactInfo>
        <ContactInfo>Telf. 985350937 · tcomadresfeministas@gmail.com</ContactInfo>
        <ContactInfo>Última actualización: 15/09/2024</ContactInfo>
      </ContactSection>
    </FooterContainer>
  );
};

export default Footer;