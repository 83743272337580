import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const LibrosContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const LibrosGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 2rem 0;
  margin-bottom: 4rem;
`;

const LibroCard = styled.div`
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 1.5rem;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 auto;
  width: 150px;
  height: 200px;
  background: #f5f5f5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
    align-items: center;
  }
`;

const LibroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
`;

const LibroInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Content = styled.div`
  color: #444;
  line-height: 1.6;
  font-size: 1rem;
  ${props => !props.expanded && `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
  
  p {
    margin-bottom: 0.5rem;
  }
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.4rem;
  margin: 0;
  line-height: 1.3;
`;

const Edition = styled.p`
  color: #666;
  font-size: 0.9rem;
  margin: 0;
  font-style: italic;
`;

const PageHeader = styled.div`
  text-align: center;
  padding: 3rem 0;
  background: linear-gradient(135deg, #8a2be2 0%, #cc99ff 100%);
  color: white;
  margin: -2rem -2rem 2rem -2rem;
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  }
  
  p {
    font-size: 1.1rem;
    max-width: 600px;
    margin: 0 auto;
    opacity: 0.9;
  }
`;

const FilterSection = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 25px;
  background: ${props => props.active ? '#8a2be2' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.active ? '#7B1FA2' : '#e0e0e0'};
  }
`;

const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: #8a2be2;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
  
  &:hover {
    color: #7B1FA2;
  }
`;

const Libros = () => {
  const [libros, setLibros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  const toggleExpanded = (id) => {
    setExpandedCards(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const renderContent = (content) => {
    if (!content || !Array.isArray(content)) return null;
    
    return content.map((block, index) => {
      if (block.type === 'paragraph') {
        return <p key={index}>{block.children[0].text}</p>;
      }
      return null;
    });
  };

  useEffect(() => {
    const fetchLibros = async () => {
      try {
        const token = process.env.REACT_APP_API_TOKEN;
        const response = await axios.get(`${apiUrl}/api/libros?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLibros(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching libros:', err);
        setError('Error al cargar los libros');
        setLoading(false);
      }
    };

    fetchLibros();
  }, [apiUrl]);

  if (loading) return <div>Cargando libros...</div>;
  if (error) return <div>{error}</div>;

  return (
    <LibrosContainer>
      <PageHeader>
        <h1>Libros publicados por les comadres</h1>
        <p>Explora nuestra colección de publicaciones que reflejan nuestra historia y pensamiento</p>
      </PageHeader>

      <LibrosGrid>
        {libros.map((libro) => (
          <LibroCard key={libro.id}>
            <ImageContainer>
              {libro.Imagen && (
                <LibroImage 
                  src={`${apiUrl}${libro.Imagen[0].url}`}
                  alt={libro.Titulo}
                />
              )}
            </ImageContainer>
            <LibroInfo>
              <Title>{libro.Titulo}</Title>
              <Edition>{libro.Edicion}</Edition>
              <Content expanded={expandedCards[libro.id]}>
                {renderContent(libro.Contenido)}
              </Content>
              <ReadMoreButton onClick={() => toggleExpanded(libro.id)}>
                {expandedCards[libro.id] ? 'Leer menos' : 'Leer más'}
              </ReadMoreButton>
            </LibroInfo>
          </LibroCard>
        ))}
      </LibrosGrid>

      <PageHeader>
        <p>Estos libros han sido editados gracias a la colaboración y subvenciones procedentes de los distintos organismos de la mujer del Gobierno del Principado de Asturias y del Ayuntamiento de Gijón.</p>
      </PageHeader>
    </LibrosContainer>
  );
};

export default Libros;
