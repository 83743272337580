import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const EnlaceContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const EnlaceCard = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const EnlaceTitle = styled.h2`
  color: #333;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const EnlaceLink = styled.a`
  color: #8a2be2;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const EnlaceDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const Enlace = () => {
  const [enlaces, setEnlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEnlaces = async () => {
      try {
        const token = process.env.REACT_APP_API_TOKEN;
        let allEnlaces = [];
        let page = 1;
        
        // Primera llamada para obtener el total de páginas
        const initialResponse = await axios.get(
          `${apiUrl}/api/enlaces?pagination[page]=1&pagination[pageSize]=100`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const totalPages = initialResponse.data.meta.pagination.pageCount;

        // Procesar todas las páginas
        for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
          const response = await axios.get(
            `${apiUrl}/api/enlaces?pagination[page]=${currentPage}&pagination[pageSize]=100`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );

          const { data } = response.data;
          allEnlaces = [...allEnlaces, ...data];
          
          // Pequeña pausa entre peticiones
          if (currentPage < totalPages) {
            await new Promise(resolve => setTimeout(resolve, 300));
          }
        }

        setEnlaces(allEnlaces);
        setLoading(false);
      } catch (err) {
        console.error('Error al cargar enlaces:', err);
        setError('Error al cargar los enlaces');
        setLoading(false);
      }
    };

    fetchEnlaces();
  }, [apiUrl]);

  if (loading) return (
    <EnlaceContainer>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        Cargando enlaces...
      </motion.div>
    </EnlaceContainer>
  );
  
  if (error) return (
    <EnlaceContainer>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {error}
      </motion.div>
    </EnlaceContainer>
  );

  return (
    <EnlaceContainer>
      <AnimatePresence>
        {enlaces.map((enlace) => (
          <EnlaceCard
            key={enlace.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <EnlaceTitle>
              <EnlaceLink 
                href={enlace.URL} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {enlace.Title}
              </EnlaceLink>
            </EnlaceTitle>
            {enlace.Description && (
              <EnlaceDescription>
                {enlace.Description}
              </EnlaceDescription>
            )}
          </EnlaceCard>
        ))}
      </AnimatePresence>
    </EnlaceContainer>
  );
};

export default Enlace; 