import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import EmbeddedBlog from './components/EmbeddedBlog';
import Sidebar from './components/Sidebar';
import PostsList from './components/PostsList';
import PageContent from './components/PageContent';



const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f7f9fc;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }
`;

const HomeContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 1024px) {
    padding: 1rem;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    border-radius: 8px;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    gap: 0.75rem;
  }
`;

const Title = styled.h1`
  color: #2d3748;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const Paragraph = styled.p`
  color: #34495e;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const LoadingText = styled.p`
  color: #7f8c8d;
  font-size: 1.2rem;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
  margin: 1rem 0;
  border-radius: 5px;
  display: block;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
  margin: 1rem 0;
  border-radius: 5px;
  display: block;
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #fadbd8;
  border-radius: 5px;
`;
const VideoContainer = styled.div`
  margin: 1rem 0;
`;

const VideoErrorMessage = styled.div`
  padding: 1rem;
  background-color: #fff3cd;
  color: #856404;
  border-radius: 5px;
  margin: 1rem 0;
  text-align: center;
`;

const DownloadLink = styled.a`
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #2c3e50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    background-color: #34495e;
  }
`;

const BlogSection = styled.section`
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;

const BlogTitle = styled.h2`
  text-align: center;
  font-size: clamp(1.5rem, 3vw, 2.25rem);
  margin-bottom: 2rem;
  
  &:after {
    width: clamp(40px, 5vw, 60px);
    height: 3px;
  }

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

const BlogContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    border-radius: 8px;
  }
`;

const SidebarStyled = styled.div`
  width: 280px;
  padding: 1.5rem;
  
  h3 {
    color: #351c75;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #351c75;
  }
  
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  li {
    width: 100%;
    
    a, button {
      display: block;
      width: 100%;
      padding: 0.5rem 1rem;
      color: #000000;
      text-decoration: none;
      border: 1px solid #000000;
      background-color: #cc99ff;
      border-radius: 4px;
      transition: all 0.2s ease;
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      
      &:hover {
        background-color: rgb(153, 51, 204);
        transform: translateX(4px);
      }
      
      &:active {
        background-color: rgb(153, 51, 204) !important;
        transform: scale(0.98);
        transition: none;
      }
      
      &.active {
        background-color: rgb(153, 51, 204);
        color: white;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem;
    
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    li {
      a, button {
        padding: 0.75rem 1rem;
        transform: none;
        
        &:hover {
          transform: none;
          background-color: rgb(153, 51, 204);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    
    li {
      a, button {
        padding: 0.5rem;
        font-size: 0.9rem;
      }
    }
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 12px;
  min-height: 400px;

  @media (max-width: 1024px) {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
    min-height: 300px;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }

  h2 {
    font-size: clamp(1.2rem, 3vw, 1.75rem);
    margin-bottom: 1rem;
  }
`;

const PostItem = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 1rem 0;
  
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    
    @media (max-width: 768px) {
      border-radius: 6px;
    }
  }
`;

const TrenDeLaLibertad = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = process.env.REACT_APP_API_TOKEN;
        
        const response = await axios.get(`${apiUrl}/api/tren-de-la-libertads?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.data[0]) {
          setData(response.data.data[0]);
        } else {
          setError('No se encontraron datos');
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setError('Error al cargar los datos. Por favor, intente más tarde.');
      }
    };

    fetchData();
  }, []);

  const renderChildren = (children) => {
    return children.map((child, index) => {
      if (child.type === 'text') {
        return <span key={index}>{child.text}</span>;
      }
      return null;
    });
  };

  const renderContent = (content) => {
    if (!Array.isArray(content)) return null;

    return content.map((item, index) => {
      switch (item.type) {
        case 'image':
          return item.image ? (
            <ImageContainer key={`image-${index}`}>
              <Image
                src={`${item.image.url}`}
                alt={item.image.alternativeText || ''}
              />
            </ImageContainer>
          ) : null;

        case 'paragraph':
          return item.children ? (
            <Paragraph key={`paragraph-${index}`}>
              {renderChildren(item.children)}
            </Paragraph>
          ) : null;

        default:
          return null;
      }
    });
  };

  const renderVideo = (video) => {
    if (!video || !video.url) return null;

    const videoUrl = `${process.env.REACT_APP_API_URL}${video.url}`;
    
    return (
      <VideoContainer>
        <Video controls>
          <source src={videoUrl} type={video.mime} />
          <track kind="captions" />
          <VideoErrorMessage>
            El formato de video WMV puede no ser compatible con tu navegador.
            <br />
            <DownloadLink href={videoUrl} download>
              Descargar video
            </DownloadLink>
          </VideoErrorMessage>
        </Video>
      </VideoContainer>
    );
  };

  const handleMainContent = () => {
    setSelectedPageId(null);
  };

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <>
      <GlobalStyle />
      <HomeContainer>
        {data ? (
          <>
            <Title>{data.title}</Title>
            {data.content && renderContent(data.content)}
            {data.viedo && data.viedo[0] && renderVideo(data.viedo[0])}
          </>
        ) : (
          <LoadingText>Cargando...</LoadingText>
        )}
        
        <BlogSection>
          <BlogTitle>Desde Nuestro Blog</BlogTitle>
          <BlogContainer>
            <SidebarStyled>
              <Sidebar onSelectPage={setSelectedPageId} onMainContent={handleMainContent} />
            </SidebarStyled>
            <MainContent>
              {selectedPageId ? <PageContent pageId={selectedPageId} /> : <PostsList />}
            </MainContent>
          </BlogContainer>
        </BlogSection>
      </HomeContainer>
    </>
  );
};

export default TrenDeLaLibertad;