import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #cc99ff;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 300px;
  height: 100vh;
  background-color: #8a2be2;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  padding: 2rem;
  overflow-y: auto;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

const MobileNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
`;

const MobileNavItem = styled.li`
  margin: 1rem 0;
`;

const MobileNavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  display: block;
  padding: 0.5rem 0;

  &:hover {
    color: #d2c4b5;
  }
`;

const MobileDropdownContent = styled.div`
  margin-left: 1rem;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 999;

  @media (min-width: 1025px) {
    display: none;
  }
`;


const TopHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 2rem;
  
  @media (max-width: 1024px) {
    position: static;
  }
`;

const Logo = styled.div`
  text-align: center;
  color: white;
  flex-grow: 1;
  
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
  }
`;

const LogoImage = styled.img`
  width: 80px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

const NewsletterButton = styled.button`
  background-color: #d2c4b5;
  color: #4a5d4e;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
`;

const MobileMenuIcon = styled.div`
  display: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 17px;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const NavBar = styled.nav`
  background-color: #8a2be2;
  padding: 0.5rem 0;

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;

const NavList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NavItem = styled.li`
  position: relative;
  margin: 0 0.5rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;

  &:hover {
    color: #d2c4b5;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #cc9afd;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
`;

const DropdownItem = styled(Link)`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.8rem;

  &:hover {
    background-color: #aa77dd;
  }
`;

const NavItemWithDropdown = styled(NavItem)`
  &:hover ${DropdownContent} {
    display: block;
  }
`;



const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (key) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <HeaderContainer>
      <TopHeader>
        <LogoContainer>
          <LogoImage src="/logo.jpeg" alt="Logo" />
        </LogoContainer>
        <Logo>
          <h1>Tertulia Feminista Les Comadres</h1>
        </Logo>
        <MobileMenuIcon onClick={toggleMenu}>
          ☰
        </MobileMenuIcon>
      </TopHeader>
     
      <NavBar>
        <NavList>
          <NavItem><NavLink to="/">Inicio</NavLink></NavItem>          
          <NavItemWithDropdown>
            <NavItem><NavLink to="/la-tertulia/historia">La Tertulia</NavLink></NavItem>
            <DropdownContent>
              <DropdownItem to="/la-tertulia/historia">Historia y Fines</DropdownItem>
              <DropdownItem to="/la-tertulia/estatutos">Estatutos</DropdownItem>
            </DropdownContent>
          </NavItemWithDropdown>
          <NavItemWithDropdown>
            <NavItem><NavLink to="/hemeroteca">Hemeroteca</NavLink></NavItem>
            <DropdownContent>
              <DropdownItem to="/hemeroteca/noticias-propias">Noticias Propias</DropdownItem>
              <DropdownItem to="/hemeroteca/otras-noticias">Otras Noticias</DropdownItem>
            </DropdownContent>
          </NavItemWithDropdown>
          <NavItemWithDropdown>
            <NavItem><NavLink to="/jueves-comadres">Jueves Comadres</NavLink></NavItem>
            <DropdownContent>
              <DropdownItem to="/jueves-comadres/fiesta">Nuestra Fiesta</DropdownItem>
              <DropdownItem to="/jueves-comadres/carteles-fiesta">Carteles Fiesta</DropdownItem>
              <DropdownItem to="/jueves-comadres/comadres-history">Comadres y Felpeyo</DropdownItem>
            </DropdownContent>
          </NavItemWithDropdown>
          <NavItem><NavLink to="/agenda">Agenda</NavLink></NavItem>
          <NavItem><NavLink to="/tren-de-la-libertad">El Tren de la Libertad</NavLink></NavItem>
          <NavItem><NavLink to="/archivos">Archivos</NavLink></NavItem>
          <NavItem><NavLink to="/galeria">Galería</NavLink></NavItem>
          <NavItem><NavLink to="/memorias">Memorias</NavLink></NavItem>
          <NavItemWithDropdown>
            <NavLink to="/publicaciones">Publicaciones</NavLink>
            <DropdownContent>
              <DropdownItem to="/publicaciones/libros">Libros</DropdownItem>
              <DropdownItem to="/publicaciones/cd">CD</DropdownItem>
            </DropdownContent>
          </NavItemWithDropdown>
          <NavItem><NavLink to="/enlaces">Enlaces</NavLink></NavItem>
        </NavList>
      </NavBar>

       {/* Menú móvil */}
       <Overlay isOpen={isOpen} onClick={toggleMenu} />
      <Sidebar isOpen={isOpen}>
        <CloseButton onClick={toggleMenu}>×</CloseButton>
        <MobileNavList>
          <MobileNavItem>
            <MobileNavLink to="/" onClick={toggleMenu}>Inicio</MobileNavLink>
          </MobileNavItem>
          
          <MobileNavItem>
            <MobileNavLink as="div" onClick={() => toggleDropdown('tertulia')}>
              La Tertulia
            </MobileNavLink>
            <MobileDropdownContent isOpen={openDropdowns.tertulia}>
              <MobileNavLink to="/la-tertulia/historia" onClick={toggleMenu}>Historia y Fines</MobileNavLink>
              <MobileNavLink to="/la-tertulia/estatutos" onClick={toggleMenu}>Estatutos</MobileNavLink>
            </MobileDropdownContent>
          </MobileNavItem>

          <MobileNavItem>
            <MobileNavLink as="div" onClick={() => toggleDropdown('hemeroteca')}>
              Hemeroteca
            </MobileNavLink>
            <MobileDropdownContent isOpen={openDropdowns.hemeroteca}>
              <MobileNavLink to="/hemeroteca/noticias-propias" onClick={toggleMenu}>Noticias Propias</MobileNavLink>
              <MobileNavLink to="/hemeroteca/otras-noticias" onClick={toggleMenu}>Otras Noticias</MobileNavLink>
            </MobileDropdownContent>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink as="div" onClick={() => toggleDropdown('jueves-comadres')}>
              Jueves Comadres
            </MobileNavLink>
            <MobileDropdownContent isOpen={openDropdowns['jueves-comadres']}>
              <MobileNavLink to="/jueves-comadres/fiesta" onClick={toggleMenu}>Nuestra Fiesta</MobileNavLink>
              <MobileNavLink to="/jueves-comadres/carteles-fiesta" onClick={toggleMenu}>Carteles Fiesta</MobileNavLink>
              <MobileNavLink to="/jueves-comadres/comadres-history" onClick={toggleMenu}>Comadres y Felpeyo</MobileNavLink>
            </MobileDropdownContent>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/agenda" onClick={toggleMenu}>Agenda</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/tren-de-la-libertad" onClick={toggleMenu}>El Tren de la Libertad</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/archivos" onClick={toggleMenu}>Archivos</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/galeria" onClick={toggleMenu}>Galería</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/memorias" onClick={toggleMenu}>Memorias</MobileNavLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink as="div" onClick={() => toggleDropdown('publicaciones')}>
              Publicaciones
            </MobileNavLink>
            <MobileDropdownContent isOpen={openDropdowns.publicaciones}>
              <MobileNavLink to="/publicaciones/libros" onClick={toggleMenu}>Libros</MobileNavLink>
              <MobileNavLink to="/publicaciones/cd" onClick={toggleMenu}>CD</MobileNavLink>
            </MobileDropdownContent>
          </MobileNavItem>
          <MobileNavItem>
            <MobileNavLink to="/enlaces" onClick={toggleMenu}>Enlaces</MobileNavLink>
          </MobileNavItem>
        </MobileNavList>
      </Sidebar>
    </HeaderContainer>
  );
};

export default Header;
